import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeoAddressService {
    private indexedComponents: any;
    private formatted: any;
    private components: any;

    constructor() {}

    setDependency(searchObj: any) {
        this.formatted = searchObj.formatted_address;
        this.components = searchObj.address_components;
        this.indexedComponents = this._getComponentsIndexedByType();
        return this;
    }

    getAddress() {
        return {
            line1: this.getLine1(),
            line2: this.getLine2(),
            city: this.getCity(),
            state: this.getState(),
            stateCode: this.getStateCode(),
            country: this.getCountry(),
            countryCode: this.getCountryCode(),
            postalCode: this.getPostalCode(),
        };
    }

    private _getComponentsIndexedByType() {
        var allowedTypes = [
            'administrative_area_level_1',
            'administrative_area_level_2',
            'locality',
            'sublocality_level_1',
            'postal_town',
            'country',
            'route',
            'street_number',
            'postal_code',
        ];
        var indexedComponents = {};
        var components = this.components;
        for (var index in this.components) {
            if (!this.components.hasOwnProperty(index)) {
                continue;
            }
            var currentTypes = allowedTypes.filter(function(item) {
                return components[index].types.indexOf(item) !== -1;
            });
            indexedComponents[currentTypes[0]] = {
                short_name: this.components[index].short_name,
                long_name: this.components[index].long_name
            };
        }
        return indexedComponents;
    }
    
    getLine1() {
        var route = (this.indexedComponents.route && this.indexedComponents.route.long_name) || '';
        var regex = RegExp('^(.*)' + route.split(' ',1)[0]), // get all the user entered values before a match with the first word from the Google result
            result = regex.exec(this.formatted);

        if (Array.isArray(result)) {
            return result[1] + '' + route; // add the street name to the user-entered unit & street number
        }
        return route;
    }
    getLine2() {
        return (this.indexedComponents.sublocality_level_1 && this.indexedComponents.sublocality_level_1.long_name) || '';
    }
    getCity() {
        return ((this.indexedComponents.locality && this.indexedComponents.locality.long_name)
            || (this.indexedComponents.postal_town && this.indexedComponents.postal_town.long_name)) || '';
    }
    getState() {
        var stateKey = this._getStateKey();
        return (this.indexedComponents[stateKey] && this.indexedComponents[stateKey].long_name) || '';
    }
    getStateCode() {
        var stateKey = this._getStateKey();
        return (this.indexedComponents[stateKey] && this.indexedComponents[stateKey].short_name) || '';
    }
    getCountry() {
        return (this.indexedComponents.country && this.indexedComponents.country.long_name) || '';
    }
    getCountryCode() {
        return this.indexedComponents.country && this.indexedComponents.country.short_name;
    }
    getPostalCode() {
        return (this.indexedComponents.postal_code && this.indexedComponents.postal_code.long_name) || '';
    }
    private _getStateKey() {
        // Determining which field to use as state is bit tricky because for some country
        // administrative_area_level_2 is not a always a state ie. 98 Balcombe Ave, Worthing BN14, UK
        // To identify state from these two field, we will find the field with state code in short name.
        // Technically where short_name and long_name is not equal and short name equal or less than three character
        if (this.indexedComponents.administrative_area_level_1
                && this.indexedComponents.administrative_area_level_1.long_name != this.indexedComponents.administrative_area_level_1.short_name
                && this.indexedComponents.administrative_area_level_1.short_name.length <= 3)
        {
                return 'administrative_area_level_1';
        }
        return 'administrative_area_level_2'; // Default
    }
}
