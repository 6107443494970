<div>
    <div class="section fullSection">
        <img class="clientLogo" [src]='data.logo'/>
    </div>

    <div class="section fullSection" *ngIf="data.banner">
        <img [src]='data.banner' class="clientBanner"/>
    </div>

    <hr class="greyLine" [ngStyle]="{'background' : data.primaryColour}" />

        <div class="row">
        <div class="section col-9">

            <div class="bookName" *ngIf="data.booking">
                <h1 [innerHTML]="data.booking.name"></h1>
            </div>

            <div class="container" *ngIf="currentStep && currentStep !== 1 && currentStep !== 4">
                 <div class="breadcrumb">
                    <a [ngClass]="{'doneSelectedStep': currentStep == 2 || currentStep == 3}" (click)="proccedToStepOne()">
                        <span class="breadcrumb__inner">
                            <span class="breadcrumb__title">Step 1</span>
                            <span class="breadcrumb__desc">Select a Room</span>
                        </span>
                    </a>
                    <a [ngClass]="{'selectedStep': currentStep == 2, 'doneSelectedStep': currentStep == 3}" (click)="proccedToStepTwo()">
                        <span class="breadcrumb__inner">
                            <span class="breadcrumb__title">Step 2</span>
                            <span class="breadcrumb__desc">Enter Booking Details</span>
                        </span>
                    </a>
                    <a [ngClass]="{'selectedStep': currentStep == 3}">
                        <span class="breadcrumb__inner">
                            <span class="breadcrumb__title">Confirm Your Booking</span>
                        </span>
                    </a>
                </div>
            </div>

            <div class="bookTable" [ngClass]="{'fullTableWrap' : currentStep != 1}">
                 <app-room-booker></app-room-booker>
            </div>

            <br /><br />

            <div id="lowerContent" *ngIf="currentStep == 1">
                <h1>ABOUT THE EVENT</h1>
                <p [innerHTML]="data.eventDescription"></p>

                <br />

                <h1>ABOUT THIS PROPERTY</h1>
                <div class="propertyInfo" *ngIf="data.venue">
                    <img [src]="data.venue.image" />

                    <div class="hotelInformation">
                        <h2 [innerHTML]="data.venue.name"></h2>
                        <h3>At {{data.venue.address}}</h3>
                        <p [innerHTML]="data.venue.description" class="pullCopyUp moreTextContainer" [ngClass]="{'moreTextContainerShow' : togglePropertyDesc, 'moreTextContainer' : !togglePropertyDesc}"></p>
                        <a *ngIf="!togglePropertyDesc" (click)="togglePropertyDesc = true">more</a>
                        <a *ngIf="togglePropertyDesc" (click)="togglePropertyDesc = false">less</a>
                    </div>

                </div>

                <div class="propertyTopSpace" *ngIf="data.venue">
                    <h3>Available Rooms</h3>

                    <div class="roomsWrapper" *ngFor='let room of propertyRooms'>

                         <div class="roomDiv">
                            <img [src]="room.roomImage" />
                            <div class="roomContentWrap">
                                <label class="currHotel">{{room.roomType}}</label>

                                <br /><br />
                                <i class="icon-people"></i> <label> {{room.roomNumStandardAdults}} People</label>

                                <br /><br />
                                <label *ngIf="!room.room_smokingAllowed"><i class="icon-fire"></i> <label> Non-smoking Room </label></label>
                                <label *ngIf="room.room_smokingAllowed"><i class="icon-fire"></i> <label> Smoking Room </label></label>

                            </div>

                            <div class="roomTimesSlider" *ngIf="room.roomAvailability ">
                                <div class="availText"><i class="icon-calendar"></i> Availabilty </div>

                                <div class="slickClass">
                                    <div *ngFor="let day of room.roomAvailability; let i = index;">
                                        <div class="timeBlock" *ngIf="!day.hide" [ngClass]="{'disableRoom' : !day.available}">
                                            {{day.date}}
                                            <span *ngIf="day.available">{{day.cost | currency : data.venue.currencyCode }}</span>
                                            <span *ngIf="!day.available"><br /></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <div class="section col-3" *ngIf="currentStep == 1">
            <div class="whenTop" [ngStyle]="{'background' : data.primaryColour}">
                 When <span class="arrow-down" [ngStyle]="{'border-top' : '10px solid ' + data.primaryColour}"></span>
            </div>

            <div class="flexCalander">
                <div class="block calandarBlock" *ngIf="data.date">
                  <p><i class="icon-calendar"></i></p>
                </div>
                <div class="block bookDate" *ngIf="data.date">
                    <p><span [innerHTML]="data.date.day"></span></p>
                    <p><span [innerHTML]="data.date.month"></span>&nbsp;<span [innerHTML]="data.date.year"></span></p>
                </div>

                <div class="block bookTime" *ngIf="data.booking">
                    <!--<span>
                      <i class="icon-clock"></i> <label [innerHTML]="data.booking.dateEventStart"></label>
                    </span>
                     <span>
                      <i class="icon-calendar"></i> <label><a href="#">Add to calander</a></label>
                    </span> -->
                </div>
            </div>

            <br /><br />

            <div class="whenTop" [ngStyle]="{'background' : data.primaryColour}">
                 Where <span class="arrow-down" [ngStyle]="{'border-top' : '10px solid ' + data.primaryColour}"></span>
            </div>

            <div class="section mapFrame" *ngIf="data.gMapURL">
                <iframe width="96%" height="300px" frameborder="0" [src]="data.gMapURL"></iframe>
            </div>

            <div class="addressFrame" *ngIf="data.booking">
                <i class="icon-location-pin"></i><p class="addressBottom" [innerHTML]="data.venue.address"></p>
            </div>

        </div>
    </div>

    <app-footer [isHome]="false"></app-footer>
</div>
