import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import {ShowFormService} from '../../services/show-form.service';
import {ApiService} from '../../services/api.service';
import {StorageService} from '../../services/storage.service';
import {Constants} from '../../constants';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent {
    venueData: any = [];
    bookingCode: string;
    errorMessage: string;
    canGenerate: boolean = true;
    isSaving: boolean = false;
    venue: string;
    account: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private showFormService: ShowFormService,
        private apiService: ApiService,
        private storageService: StorageService,
        private consts: Constants,
    ) {}

    ngOnInit() {
        // Get params
        this.route.paramMap.subscribe(params => {
            this.account = params.get('account');
            this.venue = params.get('venue');
        });

        // Get venue data from api.
        this.apiService.getVenueData(this.account, this.venue)
            .subscribe(
                data => {
                    let res: any = data;
                    if (res.success === true) {
                        this.venueData = res.data;
                        this.showFormService.setVenueData(res.data);
                    }
                    else {
                        this.router.navigateByUrl('/404');
                    }
                },
                error => {
                    this.router.navigateByUrl('/404');
                }
            );

        // Clear everything stored in local storge.
        this.storageService.cleanAll();
    }

    procced() {        
        this.errorMessage = '';
        if (this.bookingCode && this.bookingCode.length > 1) {
            this.isSaving = true;
            this.apiService
                .getBookingData(this.account, this.venue, this.bookingCode)
                .subscribe(
                    data => {
                        let bookingdData: any = data;
                        if (bookingdData.success) {
                            this.showFormService.setMainData(bookingdData.data);
                            this.router.navigate(['./book/' + this.bookingCode], {relativeTo: this.route});
                        }
                        else {
                            this.errorMessage = this.consts.MESSAGES.ERROR_INVALID_CODE;
                        }
                        this.isSaving = false;
                    },
                    error => {
                        this.isSaving = false;
                    }
                );
        }
        else {
            this.errorMessage = this.consts.MESSAGES.ERROR_INVALID_CODE;
        }
    }
}
