<span>
    <h3 class="bookYourText" *ngIf="currentStep == 1">Select your room/s</h3>
    <h3 class="bookYourText" *ngIf="currentStep == 2">Book your room</h3>
    <h3 class="bookYourText" *ngIf="currentStep == 3">Confirm your booking</h3>
</span>

<!-- Choose rooms to book - start -->
<div class="selectFormWrapper" *ngIf="currentStep == 1">
    <div class="alert alert-danger">
        <div *ngFor="let error of roomFormErrors;">
            {{ error }}
    </div>
</div>
<form [formGroup]="selectRoomForm" (ngSubmit)="onSubmitRoomsForm()">
    <table id="roomTable">
        <tr>
            <th>Room Name</th>
            <th>Avg. Nightly Rate</th>
            <th>Rooms</th>
        </tr>
        <tr class="selectLeftRoom" *ngFor="let room of propertyRooms; let i = index" [ngClass]="{'highlightRow' : room && room.id && !selectRoomForm.controls['group_' + room.id].invalid}">
            <td><label class="dottedRoom">{{ room.roomType }}</label></td>
            <td>{{ room.averageCost | currency : data.venue.currencyCode }}</td>
            <td>
                <select formControlName="group_{{room.id}}" required>
                    <option></option>
                    <option *ngFor="let k of createRange(room.maxNumRooms)" [value]="k">{{k}}</option>
                </select>
            </td>
        </tr>
        <button class="nextBtn noRight" type="submit" id="selectRoom" [ngStyle]="{'background':  data.primaryColour }">Next</button>
    </table>
</form>
</div>
<!-- Choose rooms to book - end -->
<!-- Book your room - start -->
<div class="roomFormContainer" *ngIf="currentStep == 2">
    <!--<div class="overlay" [hidden]="!loadingBooker"></div>-->
    <form [formGroup]="bookRoomForm" (ngSubmit)="onSubmitBookRoomForm()">

        <table id="timesTable" class="timeTable">

            <!-- <h2 id="selectedRoom" class="selectRoomTxt">{{ rowTitles[i] }}</h2> -->

            <col width="20" class="columnArranger">
            <col width="100" class="columnArranger">
            <col width="100" class="columnArranger">
            <col width="100" class="columnArranger">
            <col width="60" class="columnArranger">
            <col width="100" class="columnArranger">

            <col width="100" class="columnArranger">
            <col width="100" class="columnArranger">
            <col width="20" class="columnArranger">

            <tr>
                <th></th>
                <th>Room Type</th>
                <th>Check In *</th>
                <th>Check Out *</th>
                <th>Adults *</th>
                <th>First Name *</th>
                <th>Last Name *</th>
                <th>Email *</th>
            </tr>
            <ng-container formArrayName="groups">
                <tr id="formRoom" class="fromRowGroup" *ngFor="let bookRoom of groups.controls; let j = index">
            <ng-container [formGroupName]="j">
                <td> #{{j+1}}</td>
                <td>
                    <select class="allRoomsSelect" formControlName="groupId" required [ngClass]="{'invalidInput': bookRoom.controls.groupId.invalid && (bookRoom.controls.groupId.dirty || bookRoom.controls.groupId.touched) }">
                            <option *ngFor="let propertyRoom of propertyRooms" [value]="propertyRoom.id">{{propertyRoom.roomType}}</option>
                    </select>
                    <div *ngIf="bookRoomFormErrors && bookRoomFormErrors[j] && bookRoomFormErrors[j].groupId" class="alert alert-danger">
                        {{ bookRoomFormErrors[j].groupId }}
                </div>
            </td>
            <td>
                <input type="date" formControlName="arrivalDate" class="checkin" required [ngClass]="{'invalidInput': bookRoom.controls.arrivalDate.invalid && (bookRoom.controls.arrivalDate.dirty || bookRoom.controls.arrivalDate.touched) }"/>
                       <div *ngIf="bookRoomFormErrors && bookRoomFormErrors[j] && bookRoomFormErrors[j].arrivalDate" class="alert alert-danger">
                       {{ bookRoomFormErrors[j].arrivalDate }}
            </div>
        </td>
        <td>
            <input type="date" formControlName="departureDate" class="checkout" required [ngClass]="{'invalidInput': bookRoom.controls.departureDate.invalid && (bookRoom.controls.departureDate.dirty || bookRoom.controls.departureDate.touched) }" />
                   <div *ngIf="bookRoomFormErrors && bookRoomFormErrors[j] && bookRoomFormErrors[j].departureDate" class="alert alert-danger">
                   {{ bookRoomFormErrors[j].departureDate }}
        </div>
    </td>
    <td>
        <select formControlName="numAdultGuests" required [ngClass]="{'invalidInput': bookRoom.controls.numAdultGuests.invalid && (bookRoom.controls.numAdultGuests.dirty || bookRoom.controls.numAdultGuests.touched) }">
                <option></option>
            <option *ngFor="let k of createRange(10)" value="{{k}}">{{k}}</option>
        </select>
        <div *ngIf="bookRoomFormErrors && bookRoomFormErrors[j] && bookRoomFormErrors[j].numAdultGuests" class="alert alert-danger">
            {{ bookRoomFormErrors[j].numAdultGuests }}
    </div>
</td>
<td><input type="text" formControlName="firstName" class="first" required [ngClass]="{'invalidInput': bookRoom.controls.firstName.invalid && (bookRoom.controls.firstName.dirty || bookRoom.controls.firstName.touched) }"/></td>
<td><input type="text" formControlName="lastName" class="last" required [ngClass]="{'invalidInput': bookRoom.controls.lastName.invalid && (bookRoom.controls.lastName.dirty || bookRoom.controls.lastName.touched) }"/></td>
<td><input type="email" formControlName="email" class="email" required email [ngClass]="{'invalidInput': bookRoom.controls.email.invalid && (bookRoom.controls.email.dirty || bookRoom.controls.email.touched) }"/></td>
<td class="removeTH" (click)="removeRoom(j)"><i class="icon-close"></i></td>
</ng-container>
</tr>

</ng-container>
</table>
<button class="nextBtn" id="finishAll" type="submit" [ngStyle]="{'background':  data.primaryColour }" [disabled]="isSaving">Next</button>
<span appSpinLoader [hidden]="!isSaving" class="loader"></span>
<span class="startOverText">or <a (click)="startOver()">Start Over</a></span>
</form>
</div>

<!-- Book your room - end -->

<!-- Confirm your booking - start-->
<div class="successZone" *ngIf="currentStep == 3">
    <table class="timeTable">
        <tr>
            <th>Room Type</th>
            <th>Check In</th>
            <th>Check Out</th>
            <th>Adults</th>
            <th>Guest Name</th>
            <th>Guest Email</th>
            <th>Total Cost (Ex. Tax)</th>
        </tr>

        <tr *ngFor="let room of finalGroups" class="fromRowGroup">
            <td>{{ getRoomById(room['groupId']) ? getRoomById(room['groupId']).roomType : '' }}</td>
            <td>{{room['arrivalDate'] | date:'MMM d, y':'UTC'}}</td>
            <td>{{room['departureDate'] | date:'MMM d, y':'UTC'}}</td>
            <td>{{room['numAdultGuests']}}</td>
            <td>{{ room['firstName'] }} {{ room['lastName'] }} </td>
            <td>{{room['email']}}</td>
            <td>{{room['totalCost'] | currency : data.venue.currencyCode}}</td>
        </tr>
        <tbody class="totals">
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="totalHeading">Sub Total</td>
            <td>{{ summary.subtotal | currency: data.venue.currencyCode}}</td>
        </tr>
        <tr *ngFor="let tax of summary.taxes">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{tax['taxName']}}</td>
            <td>{{tax['tax'] | currency: data.venue.currencyCode}}</td>
        </tr>
        <tr *ngFor="let fee of summary.fees">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{{fee['feeName']}}</td>
            <td>{{fee['fee'] | currency: data.venue.currencyCode}}</td>
        </tr>
        <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="totalHeading">Total</td>
            <td>{{summary.total | currency: data.venue.currencyCode}}</td>
        </tr>
        </tbody>
    </table>
    <div class="alert alert-danger">
        <div *ngFor="let error of checkoutFormErrors;">
            {{ error }}
    </div>
</div>
<h3 class="bookYourText">Main Contact</h3>

<form action="" [formGroup]="checkoutForm" (ngSubmit)="onSubmitCheckoutForm()">
    <div class="formRoomUL">
        <div>
            <span class="leftText">First*</span> <input type="text" name="firstName" formControlName="firstName" required [ngClass]="{'invalidInput': checkoutForm.controls.firstName.invalid && (checkoutForm.controls.firstName.dirty || checkoutForm.controls.firstName.touched) }" /><br />
            <span class="leftText">Last*</span> <input type="text" name="lastName" formControlName="lastName" required [ngClass]="{'invalidInput': checkoutForm.controls.lastName.invalid && (checkoutForm.controls.lastName.dirty || checkoutForm.controls.lastName.touched) }" />
        </div>

        <div>
            <span class="leftText">Email*</span> <input type="text" name="email" formControlName="email" required email [ngClass]="{'invalidInput': checkoutForm.controls.email.invalid && (checkoutForm.controls.email.dirty || checkoutForm.controls.email.touched) }"/><br />
            <span class="leftText">Phone*</span> <input type="text" name="phone" formControlName="phone" required [ngClass]="{'invalidInput': checkoutForm.controls.phone.invalid && (checkoutForm.controls.phone.dirty || checkoutForm.controls.phone.touched) }"/>
        </div>

        <div class="fullRow">
            <span class="leftText">Address</span> <AutocompleteComponent (setAddress)="getAddress($event)"
                adressType="geocode"
                placeholder=""
                [googleMapsApiKey]="data.venue.googleMapsApiKey"></AutocompleteComponent>
        </div>

        <div class="fullRow checkBoxRow">
            <input type="checkbox" name="agreedTerms" formControlName="agreedTerms" class="agreeCheck" required [ngClass]="{'invalidInput': checkoutForm.controls.agreedTerms.invalid && (checkoutForm.controls.agreedTerms.dirty || checkoutForm.controls.agreedTerms.touched) }"/>
                   <span>I agree to the <a (click)="openTermsModal()">Terms and Conditions</a></span>
        </div>

        <div *ngIf="checkoutForm.controls.agreedTerms.invalid && (checkoutForm.controls.agreedTerms.dirty || checkoutForm.controls.agreedTerms.touched)" class="alert alert-danger reviewTermsRow"><br />
            <div *ngIf="checkoutForm.controls.agreedTerms.errors.required">
                Please review and accept Terms and Conditions.
            </div>
        </div>
    </div>

    <button class="nextBtn" type="submit" [ngStyle]="{'background':  data.primaryColour }" [disabled]="isSaving">{{data.canPay == true ? 'Confirm & Pay' : 'Confirm'}}</button>
    <span appSpinLoader [hidden]="!isSaving" class="loader"></span>
    <span class="startOverText">or <a (click)="startOver()">Start Over</a></span>

</form>
</div>
<!-- Confirm your booking - end-->

<div class="completeSection" *ngIf="currentStep == 4">

    <h1>Your reservation is complete!</h1>
    <p>We are confirming your booking now and will send an email confirmation once complete to guests along with a summary to
        <label><a *ngIf="contactEmailSendTo">{{contactEmailSendTo}}</a></label>.
    </p>
    <!-- user is allowed to pay and if we are here, then there must be a case where payment order is not created so allow user to pay from there -->
    <div *ngIf="data.canPay">
        <button class="nextBtn" type="button" [ngStyle]="{'background':  data.primaryColour }" [disabled]="isSaving" (click)="createPayment()">Pay Now</button>
        <span appSpinLoader [hidden]="!isSaving" class="loader"></span>
        <div class="alert alert-danger">
            <div *ngFor="let error of checkoutFormErrors;">
                {{ error }}
            </div>
        </div>
    </div>
    <h3>Questions?</h3>
    Contact <b *ngIf="data.venue">{{data.venue.name}}</b> at <a href="mailto:{{data.venue.email}}" *ngIf="data.venue">{{data.venue.email}}</a>
    <br /><br />
    <button (click)="startOver()" class="nextBtn noRight" [ngStyle]="{'background':  data.primaryColour }">New Reservation</button>
</div>
