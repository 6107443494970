import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-tac',
    templateUrl: './tac.component.html',
    styleUrls: ['./tac.component.scss']
})
export class TacComponent implements OnInit {

    data: any;

    constructor(public matDialogRef: MatDialogRef<TacComponent>) {}

    ngOnInit(): void {
    }

    closeModal() {
        this.matDialogRef.close();
    }
}
