import {Component} from '@angular/core';
import {FormBuilder, FormArray, FormGroup, FormControl, Validators} from '@angular/forms';
import {ShowFormService} from '../../services/show-form.service';
import {DialogService} from '../../services/dialog.service';
import {StorageService} from '../../services/storage.service';
import {GeoAddressService} from '../../services/geo-address.service';
import {ApiService} from '../../services/api.service';

declare var $: any;

@Component({
    selector: 'app-room-booker',
    templateUrl: './room-booker.component.html',
    styleUrls: ['./room-booker.component.scss']
})
export class RoomBookerComponent {

    data: any = [];
    propertyRooms: any = [];
    finalGroups: any = [];
    summary: any = [];
    checkoutForm: any = [];
    eventAddress: any = [];
    contactEmailSendTo: string;
    rsvBookingId: null;

    // Forms
    selectRoomForm = this.formBuilder.group({});
    bookRoomForm = this.formBuilder.group({
        groups: new FormArray([])
    });
    groups: any = [];
    roomFormErrors: any = [];
    bookRoomFormErrors: any = [];
    checkoutFormErrors: any = [];

    currentStep: number = 1;
    isSaving: boolean = false;

    constructor(
        private showFormService: ShowFormService,
        private formBuilder: FormBuilder,
        private dialogService: DialogService,
        private storageService: StorageService,
        private geoAddressService: GeoAddressService,
        private apiService: ApiService,
    ) {}

    ngOnInit() {
        this.showFormService.getMainData().subscribe(data => {
            if (data) {
                this.data = data;
            }
        });

        this.showFormService.getRoomData().subscribe(data => {
            this.propertyRooms = data;
            this._calculateAverageCosts();
            // Add form controls for select room form.
            for (let i in this.propertyRooms) {
                this.selectRoomForm.addControl('group_' + this.propertyRooms[i].id, this.formBuilder.control('', Validators.required));
            }
        });
        this.showFormService.getStep().subscribe(step => {
            if (step) {
                this.currentStep = step;
            }
        });

        // Breadcrumbs
        this.showFormService.getToStepOne().subscribe(navigate => {
            if (navigate) {
                this.toStepOne();
                this.showFormService.generateSlick();
            }
        });
        this.showFormService.getToStepTwo().subscribe(navigate => {
            if (navigate) {
                this.toStepTwo();
            }
        });

        // Init checkout form
        this.checkoutForm = this.formBuilder.group({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            address: '',
            agreedTerms: ''
        });

        // Init existing data if any.
        this._initExistingData();
    }

    /**
     * Inits the existing data from storage service if the page reloads.
     */
    private _initExistingData() {

        // Set current step
        if (this.showFormService.getStepValue()) {
            this.currentStep = this.showFormService.getStepValue();
        }

        // Set groups selected by user
        if (this.storageService.get('finalGroups')) {
            this.finalGroups = this.storageService.get('finalGroups');
            this.groups = this.bookRoomForm.get('groups') as FormArray;
            for (let i in this.finalGroups) {
                let groupData = this.finalGroups[i];
                this._addGroup(groupData);
            }
        }

        // Set propery rooms
        if (this.storageService.get('getRoomDataValue')) {
            this.propertyRooms = this.showFormService.getRoomDataValue();
        }

        // Set summary if any
        if (this.storageService.get('summary')) {
            this.summary = this.storageService.get('summary');
        }
    }

    /**
     * Calculates the average costs of the rooms.
     */
    private _calculateAverageCosts() {
        for (let i in this.propertyRooms) {
            let totalCostsOfRooms = 0;

            for (let newI in this.propertyRooms[i].roomAvailability) {
                totalCostsOfRooms = +totalCostsOfRooms + +this.propertyRooms[i].roomAvailability[newI].cost
            }

            this.propertyRooms[i].averageCost = Math.round(totalCostsOfRooms / this.propertyRooms[i].roomAvailability.length);
        }
    }

    onSubmitRoomsForm() {
        // Validate form
        if (!this._isValidRoomsForm()) {
            this.roomFormErrors = ['Please select room.'];
            return;
        }

        this.roomFormErrors = [];

        // Init book room form.
        this._initBookRoomFormGroup();

        // Go to Step 2
        this.toStepTwo();
    }

    private _isValidRoomsForm() {
        for (let key in this.selectRoomForm.value) {
            // if anything selected, it's valid!
            if (this.selectRoomForm.value[key]) {
                return true;
            }
        }

        return false;
    }

    private _initBookRoomFormGroup() {
        if (!this.selectRoomForm.value) {
            return;
        }

        const groups = this.selectRoomForm.value;

        // Init the form again
        this.bookRoomForm = this.formBuilder.group({
            groups: new FormArray([])
        })

        // Set groups form array
        this.groups = this.bookRoomForm.get('groups') as FormArray;
        for (let key in groups) {
            let groupId = key.replace('group_', '');
            if (!groups[key]) {
                continue;
            }
            const groupData = this.getRoomById(groupId);
            for (let j = 0; j < groups[key]; j++) {
                this._addGroup({
                    groupId: groupId,
                    arrivalDate: groupData.startDate,
                    departureDate: groupData.endDate,
                })
            }
        }
    }

    private _addGroup(
        groupData: any) {
        this.groups.push(new FormGroup({
            groupId: new FormControl(groupData.groupId),
            arrivalDate: new FormControl(groupData.arrivalDate ? groupData.arrivalDate : this.data.booking.dateEventStart),
            departureDate: new FormControl(groupData.departureDate ? groupData.departureDate : this.data.booking.dateEventEnd),
            numAdultGuests: new FormControl(groupData.numAdultGuests ? groupData.numAdultGuests : 1),
            firstName: new FormControl(groupData.firstName ? groupData.firstName : ''),
            lastName: new FormControl(groupData.lastName ? groupData.lastName : ''),
            email: new FormControl(groupData.email ? groupData.email : '')
        }));
    }

    onSubmitBookRoomForm() {
        if (this.bookRoomForm.valid) {
            this.finalGroups = this.groups.value;
            if (!this.finalGroups) {
                return;
            }

            // Set the data to the storage
            this.storageService.set('finalGroups', this.finalGroups);

            // Convert string dates to js date object for template to format them.
            for (let i in this.finalGroups) {
                this.finalGroups[i].arrivalDate = new Date(this.finalGroups[i].arrivalDate);
                this.finalGroups[i].departureDate = new Date(this.finalGroups[i].departureDate);
            }
            this.isSaving = true;

            // Send request to validate the data
            this.apiService
                .validateResData(
                    this.data.accountDomain,
                    this.data.venue.id,
                    this.data.booking.code,
                    this.finalGroups
            )
            .subscribe(
                data => {
                    if (data.success) {
                        this.bookRoomFormErrors = [];
                        // Get summary of the totals
                        this.apiService
                            .getSummary(
                                this.data.accountDomain,
                                this.data.venue.id,
                                this.data.booking.code,
                                this.finalGroups
                            ).subscribe(
                                data => {
                                    if (data.groups) {
                                        this.finalGroups = data.groups;
                                        this.storageService.set('finalGroups', this.finalGroups);
                                    }
                                    if (data.summary) {
                                        this.summary = data.summary;
                                        this.storageService.set('summary', this.summary);
                                    }
                                }
                            );
                        this.toStepThree();
                    }
                    else {
                        this.bookRoomFormErrors = data.errors;
                    }
                    this.isSaving = false;
                },
                error => {
                    this.isSaving = false;
                }
            );
        }
        else {
            this.bookRoomForm.markAllAsTouched();
        }
    }

    removeRoom(index: number) {
        this.groups.removeAt(index);
    }

    toStepOne() {
        this.showFormService.setStep(1);
    }

    toStepTwo() {
        this.showFormService.setStep(2);
    }

    toStepThree() {
        this.showFormService.setStep(3);
    }

    toFinish() {
        this.showFormService.setStep(4);
    }

    startOver() {
        this.selectRoomForm.reset();

        window.scroll(0, 500);
        this.toStepOne();
        this.showFormService.generateSlick();
        this.storageService.cleanAll();
    }

    onSubmitCheckoutForm() {
        if (this.checkoutForm.valid) {
            const contact = this.checkoutForm.value;
            // Get the address from google maps auto complete component
            contact.address = this.geoAddressService.setDependency(this.eventAddress).getAddress();
            const postData = {
                groups: this.finalGroups,
                contact: contact,
            };
            this.isSaving = true;

            // Send request to save the data.
            this.apiService
                .saveResData(
                    this.data.accountDomain,
                    this.data.venue.id,
                    this.data.booking.code,
                    postData
                )
                .subscribe(
                    data => {
                        if (data.success) {
                            this.checkoutFormErrors = [];
                            this.contactEmailSendTo = this.checkoutForm.value.email;
                            this.checkoutForm.reset();
                            this.rsvBookingId = data.rsvBookingId;
                            if (data.payUrl) {
                                window.location.href = data.payUrl;
                            }
                            this.toFinish();
                        }
                        else {
                            this.checkoutFormErrors = data.errors;
                        }
                        this.isSaving = false;
                    },
                    error => {
                        this.isSaving = false;
                    }
                );
        }
        else {
            this.checkoutForm.markAllAsTouched();
        }
    }

    createPayment() {
        if (!this.rsvBookingId) {
            return;
        }

        this.apiService
        .createPayment(
            this.data.accountDomain,
            this.data.venue.id,
            this.data.booking.code,
            this.rsvBookingId
        )
        .subscribe(
            data => {
                if (data && data.payUrl) {
                    window.location.href = data.payUrl;
                }
                this.isSaving = false;
            },
            error => {
                this.isSaving = false;
            }
        );
    }

    getAddress(event) {
        this.eventAddress = event;
    }

    getRoomById(id) {
        for (let i in this.propertyRooms) {
            if (this.propertyRooms[i].id == id) {
                return this.propertyRooms[i];
            }
        }
        return null;
    }

    openTermsModal() {
        this.dialogService.open({
            'title': 'Terms',
            'message': this.data.venue.accommResWebTerms,
            'cancelText': 'Close'
        });
    }

    createRange(number) {
        return this.showFormService.createRange(number);
    }
}