import {BrowserModule} from '@angular/platform-browser';
import {NgModule, DEFAULT_CURRENCY_CODE} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// IVVY-TODO: VEN-2706: (Done) Keep modules, components and services declaraion in own section
// Routing
import {AppRoutingModule} from './app-routing.module';

// Components
import {AppComponent} from './app.component';
import {RoomBookerComponent} from './components/room-booker/room-booker.component';
import {HomeComponent} from './components/home/home.component';
import {BookingComponent} from './components/booking/booking.component';
import {GooglePlacesComponent} from './components/google-places/google-places.component';
import {TacComponent} from './components/tac/tac.component';
import {FooterComponent} from './components/footer/footer.component';
import {DialogComponent} from './components/dialog/dialog.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {LoaderComponent} from './components/loader/loader.component';

// Services
import {ShowFormService} from './services/show-form.service';
import {DialogService} from './services/dialog.service';
import {LoaderService} from './services/loader.service';
import {StorageService} from './services/storage.service';
import {GeoAddressService} from './services/geo-address.service';
import {ApiService} from './services/api.service';

// Interceptors
import {LoaderInterceptor} from './interceptors/loader-interceptor';

// Directives
import {SpinLoaderDirective} from './spin-loader.directive';

// Other classes
import {Constants} from './constants';

@NgModule({
    declarations: [
        AppComponent,
        RoomBookerComponent,
        HomeComponent,
        BookingComponent,
        GooglePlacesComponent,
        TacComponent,
        SpinLoaderDirective,
        FooterComponent,
        DialogComponent,
        NotFoundComponent,
        LoaderComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatDialogModule
    ],
    providers: [
        ShowFormService,
        DialogService,
        LoaderService,
        StorageService,
        GeoAddressService,
        ApiService,
        Constants,
        {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'AUD'}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
