import {Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input} from '@angular/core';
import {} from 'googlemaps';

@Component({
    selector: 'AutocompleteComponent',
    template: `
      <input class="input"
        type="text"
        [(ngModel)]="autocompleteInput"
        #addresstext style="padding: 12px 20px; border: 1px solid #ccc; width: 400px"
        >
    `,
})
export class GooglePlacesComponent implements OnInit, AfterViewInit {
    @Input() adressType: string;
    @Input() googleMapsApiKey: string;
    @Output() setAddress: EventEmitter<any> = new EventEmitter();
    @ViewChild('addresstext') addresstext: any;

    autocompleteInput: string;
    queryWait: boolean;

    constructor() {}

    ngOnInit() {}

    ngAfterViewInit() {
        this.loadScript().then(() => this.getPlaceAutocomplete()).catch(() => {window.console.log('Error loading script')});
    }

    private getPlaceAutocomplete() {
        const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
            {
                // componentRestrictions: { country: 'US' },
                types: [this.adressType]
            });
        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const place = autocomplete.getPlace();
            this.invokeEvent(place);
        });
    }

    invokeEvent(place: Object) {
        this.setAddress.emit(place);
    }

    loadScript() {
        if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
            return new Promise((resolve, reject) => {
                resolve();
            });
        }
        let script: any = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://maps.googleapis.com/maps/api/js?libraries=places&key=' + this.googleMapsApiKey;
        document.body.appendChild(script);
        return new Promise((resolve, reject) => {
            script.onload = function () {
                resolve();
            }
            script.onerror = function () {
                reject();
            }
        });
    }
}
