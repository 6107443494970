import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(
        private http: HttpClient
    ) {}

    getBookingData(
        account: string,
        venue: any,
        bookingCode: string) {
        let params = new HttpParams()
            .set('account', account)
            .set(typeof venue === 'string' ? 'seoVenue' : 'venue', venue)
            .set('bookingCode', bookingCode);

        return this.http.get(
            environment.apiUrl + '/reservation',
            {params: params}
        );
    }

    getVenueData(
        account: string,
        venue: string) {
        let params = new HttpParams()
            .set('account', account)
            .set('seoVenue', venue);
        return this.http.get(
            environment.apiUrl + '/venue/get-venue',
            {params: params}
        );
    }

    validateResData(
        account: string,
        venue: any,
        bookingCode: string,
        groups: any) {
        let params = new HttpParams()
            .set('account', account)
            .set('venue', venue)
            .set('bookingCode', bookingCode);
        return this.http.post<any>(
            environment.apiUrl + '/reservation/validate-data',
            {groups: groups},
            {params: params}
        );
    }

    getSummary(
        account: string,
        venue: any,
        bookingCode: string,
        groups: any) {
        let params = new HttpParams()
            .set('account', account)
            .set('venue', venue)
            .set('bookingCode', bookingCode);
        return this.http.post<any>(
            environment.apiUrl + '/reservation/get-summary',
            {groups: groups},
            {params: params}
        );
    }

    saveResData(
        account: string,
        venue: any,
        bookingCode: string,
        data: any) {
        let params = new HttpParams()
            .set('account', account)
            .set('venue', venue)
            .set('bookingCode', bookingCode);
        return this.http.post<any>(
            environment.apiUrl + '/reservation/save-data',
            data,
            {params: params}
        );
    }

    createPayment(
        account: string,
        venue: any,
        bookingCode: string,
        rsvBookingId: any) {
        let params = new HttpParams()
            .set('account', account)
            .set('venue', venue)
            .set('bookingCode', bookingCode)
            .set('rsvBookingId', rsvBookingId);
        return this.http.get<any>(
            environment.apiUrl + '/reservation/create-payment',
            {params: params}
        );
    }
}
