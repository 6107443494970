<div class="homeWrapper">
    <div class="midBox">
        <div class="section fullSection">
            <img class="clientLogo" [src]='venueData.accommResWebLogo' />
        </div>
        <h1 class="enterForwardTitle">Enter your Group Code:</h1>
        <input type="text" [(ngModel)]="bookingCode" name="booking" class="bookingRefInput" />
        <br /><br />
        <span *ngIf="errorMessage" class="lilErrorMessage"> {{ errorMessage }} <br /><br /></span>
        <button type="button" (click)="procced()" [ngClass]="{'greyBtn' : !canGenerate}" class="nextBtn" [ngStyle]="{'background':  venueData.accommResWebPrimaryColour }">Next</button>
        <span appSpinLoader [hidden]="!isSaving" class="loader"></span>
    </div>
</div>
<app-footer [isHome]="true"></app-footer>