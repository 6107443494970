import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {StorageService} from './storage.service';

declare var $: any;

@Injectable({providedIn: 'root'})
export class ShowFormService {
    private data = new BehaviorSubject<boolean>(false);
    private currentStep = new BehaviorSubject<number>(1);
    private mainData = new BehaviorSubject<any>('');
    private venueData = new BehaviorSubject<any>('');
    private roomData = new BehaviorSubject<any>('');

    private goToStepOne = new BehaviorSubject<boolean>(false);
    private goToStepTwo = new BehaviorSubject<boolean>(false);

    constructor(private storageService: StorageService) {}

    setData(isSwitched: boolean) {
        this.data.next(isSwitched);
    }

    getData(): Observable<any> {
        return this.data.asObservable();
    }

    setMainData(data: any) {
        this.mainData.next(data);
    }

    getMainData(): Observable<any> {
        return this.mainData.asObservable();
    }

    setVenueData(data: any) {
        this.venueData.next(data);
    }

    getVenueData(): Observable<any> {
        return this.venueData.asObservable();
    }

    setRoomData(data: any) {
        this.roomData.next(data);
        this.storageService.set('roomData', this.roomData.value);
    }

    getRoomData(): Observable<any> {
        return this.roomData.asObservable();
    }

    getRoomDataValue() {
        if (this.storageService.get('roomData')) {
            return this.storageService.get('roomData');
        }
        return this.roomData.value;
    }

    setStep(step: number) {
        this.currentStep.next(step);
        this.storageService.set('currentStep', this.currentStep.value);
    }

    getStep(): Observable<any> {
        return this.currentStep.asObservable();
    }

    getStepValue() {
        if (this.storageService.get('currentStep')) {
            return parseInt(this.storageService.get('currentStep'));
        }
        return this.currentStep.value;
    }

    setStepOne(step: boolean) {
        this.goToStepOne.next(step);
    }

    setStepTwo(step: boolean) {
        this.goToStepTwo.next(step);
    }

    getToStepOne(): Observable<any> {
        return this.goToStepOne.asObservable();
    }

    getToStepTwo(): Observable<any> {
        return this.goToStepTwo.asObservable();
    }

    generateSlick() {
        $(document).ready(function () {
            if ($('.slickClass').length > 0) {
                $('.slickClass').slick({
                    dots: true,
                    infinite: false,
                    speed: 300,
                    slidesToShow: 3,
                    slidesToScroll: 3
                });
            }
        });
    };

    createRange(num: number) {
        var items: number[] = [];
        for (var i = 1; i <= num; i++) {
            items.push(i);
        }
        return items;
    }
}
