import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private keyPrefix: string;

    constructor() {
        this.keyPrefix = 'app-';
    }

    set(key: string, data: any) {
        localStorage.setItem(this.keyPrefix + key, JSON.stringify(data));
    }

    get(key: string) {
        let data = localStorage.getItem(this.keyPrefix + key);
        return JSON.parse(data);
    }

    clear(key: string) {
        localStorage.removeItem(this.keyPrefix + key);
    }

    cleanAll() {
        localStorage.clear();
    }
}
