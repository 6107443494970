import {Component} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Router, ActivatedRoute} from '@angular/router';
import {ShowFormService} from '../../services/show-form.service';
import {ApiService} from '../../services/api.service';

@Component({
    selector: 'app-booking',
    templateUrl: './booking.component.html',
    styleUrls: ['./booking.component.scss']
})

export class BookingComponent {
    data: any = [];
    account: string;
    venue: string;
    propertyRooms: any = [];
    currentStep: number;
    togglePropertyDesc: boolean;

    constructor(
        private showFormService: ShowFormService,
        private apiService: ApiService,
        private sanitizer: DomSanitizer,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.getData()
            .then(() => this.onAfterGetData())
            .catch((err) => {this.router.navigateByUrl('/404');});
    }

    onAfterGetData() {
        this._augmentExtraData(this.data);

        this.showFormService.getRoomData().subscribe(data => {
            this.propertyRooms = data;
        });

        this.showFormService.getStep().subscribe(step => {
            this.currentStep = step;
        });

        if (this.showFormService.getStepValue()) {
            this.currentStep = this.showFormService.getStepValue();
        }

        this.showFormService.generateSlick();
    }

    getData() {
        let promise = new Promise((resolve, reject) => {
            this.showFormService.getMainData().subscribe(data => {
                this.data = data;
            });

            if (!this.data) {
                // If data not exists, try to get it again.
                let account: string;
                let venue: string;
                let booking: string;
                this.route.paramMap.subscribe(params => {
                    account = params.get('account');
                    venue = params.get('venue');
                    booking = params.get('code');
                });

                this.apiService.getBookingData(account, venue, booking)
                    .subscribe(
                        data => {
                            let bookingData: any = data;
                            if (bookingData.success) {
                                this.showFormService.setMainData(bookingData.data);
                            }
                            else {
                                reject();
                            }
                            resolve();
                        },
                        err => {
                            reject();
                        }
                    );
            }
            else {
                resolve();
            }
        });

        return promise;
    }

    private _getOrdinalNum(n) {
        return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
    }

    _augmentExtraData(data) {
        // GENERATE DATES
        var months = ["Jan", "Feb", "Mar", "April", "May", "June", "July",
            "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        let bookingDate = this.data.booking['dateEventStart'];
        let fakeDate = new Date(bookingDate);
        this.data.date = {
            'day': this._getOrdinalNum(fakeDate.getDate()),
            'month': months[fakeDate.getMonth()],
            'year': fakeDate.getFullYear()
        }

        // GENERATE MAP & ADDRESS DATA
        this.data.gMapURL = this.sanitizer.bypassSecurityTrustResourceUrl('https://maps.google.com/maps?q=' + data.venue.address + '&output=embed');
        this.data.hotel_link_address = data.venue.name + ', <a href="https://maps.google.com/maps?q=' + data.venue.address + '" target="_blank">' + data.venue.address + '</a>';

        // GENERATE ROOM DATA
        for (let i in this.data.booking.accommodation) {
            this.propertyRooms.push(this.data.booking.accommodation[i])
        }

        // SAVE DATA TO SYSTEM
        this.showFormService.setMainData(this.data);
        this.showFormService.setRoomData(this.propertyRooms);
    }

    proccedToStepOne() {
        this.showFormService.setStepOne(true)
    }

    proccedToStepTwo() {
        if (this.currentStep == 3) {
            this.showFormService.setStepTwo(true)
        }
    }
}
