import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {HomeComponent} from './components/home/home.component';
import {BookingComponent} from './components/booking/booking.component';
import {NotFoundComponent} from './components/not-found/not-found.component';

const routes: Routes = [
    {path: '404', component: NotFoundComponent},
    {path: ':account/:venue', component: HomeComponent},
    {path: ':venue', component: HomeComponent},
    {path: ':account/:venue/book/:code', component: BookingComponent},
    {path: ':venue/book/:code', component: BookingComponent},
    // otherwise redirect to 404
    {path: '**', redirectTo: '/404'},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
