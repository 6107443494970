import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog/';
import {DialogComponent} from '../components/dialog/dialog.component';
@Injectable()
export class DialogService {
    constructor(private dialog: MatDialog) {}
    dialogRef: MatDialogRef<DialogComponent>;

    public open(options) {
        this.dialogRef = this.dialog.open(DialogComponent, {
            id: 'modal-component',
            height: '750px',
            width: '1000px',
            data: {
                title: options.title,
                message: options.message,
                cancelText: options.cancelText
            }
        });
    }
}
