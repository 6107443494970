<div class="footer" [ngClass]="{'absoFooter' : isHome}">
     <div class="footer-content">
        <div class="footer-left"></div>
        <div class="footer-right">
            <div class="left-text">
                <div class="ivvy-link">
                    <span class="links-html">
                        <a [href]="homeUrl">Home</a>
                        <a (click)="openTermsModal()">Terms</a>
                        <!-- <a href="https://www.ivvy.com.au/event/DNNG38/registration/login">Login</a> -->
                    </span>
                    <span class="powered-by"></span>
                    <a class="ivvy-logo" href="https://www.ivvy.com.au" rel="noopener noreferrer" target="_blank"></a>
                </div>
            </div>
        </div>
        <div class="clr"></div>
    </div>
</div>