<div id="modal-content-wrapper">
    <header id="modal-header">
        <h1 id="modal-title">Terms</h1>
    </header>
    <section id="modal-body">
        <div class="col-xs-12">
            <p><strong>iVvy Supplier Software Terms (Effective 09/10/2018)</strong></p>
            <p>This iVvy Software and Website is operated by iVvy Pty Ltd,ACN 138 782 822 (iVvy) of 1/2 Newcastle
                Street Burleigh Heads, QLD 4220.</p>
            <p>These terms and conditions (Terms) are between iVvy (we, us, or our) and You (Your), an organization
                seeking to utilise the iVvy Software.</p>
            <p>We reserve the right to modify the Terms with 30 days prior notice by posting the revised version of
                the Terms on the iVvy website. By your continued use of the Software after that posting you accept
                of the revised Terms.</p>
        </div>
    </section>
    <footer id="modal-footer">
        <button mat-raised-button id="modal-cancel-button" (click)="closeModal()">Close</button>
    </footer>
</div>