import {Component, OnInit, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DialogService} from '../../services/dialog.service';
import {ShowFormService} from '../../services/show-form.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    @Input()
    isHome: boolean;
    homeUrl: string;
    terms: string;

    constructor(
        public dialog: DialogService,
        private route: ActivatedRoute,
        private showFormService: ShowFormService
    ) {}

    ngOnInit(): void {
        let account: string;
        let venue: string;
        this.route.paramMap.subscribe(params => {
            account = params.get('account');
            venue = params.get('venue');
        });
        this.homeUrl = account ? '/' + account + '/' + venue : '/' + venue;

        this.showFormService.getVenueData().subscribe(data => {
            this.terms = data.accommResWebTerms;
        });
    }

    openTermsModal() {
        this.dialog.open({
            'title': 'Terms',
            'message': this.terms,
            'cancelText': 'Close'
        });
    }
}
